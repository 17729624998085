import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeScreen from './components/Pages/HomeScreen/HomeScreen';
import PageNotFound from './components/Pages/PageNotFound/PageNotFound';
import Type from './components/Pages/Type/Type';
import Pet from './components/Pages/Pet/Pet';
import './App.css';
import PetTypesGallery from './components/Pages/Pets/PetTypesGallery';
import Articles from './components/Pages/Articles/Articles';
import About from './components/Pages/About/About';
import ArticleContainer from './components/Pages/Articles/ArticleContainer';
import SearchContainer from './components/Pages/Search/SearchContainer';
import TermsAndConditions from './components/Pages/TermsAndConditions/TermsAndConditions';
import TankBuilder from './components/Pages/TankBuilder/TankBuilder';

function App() {
  return (
    <div className="font-container">
      <Router>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/pets" element={<PetTypesGallery />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/articles/:name" element={<ArticleContainer />} />
          <Route path="/about" element={<About />} />
          <Route path="/tank-builder" element={<TankBuilder />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/pets/search/:search_name" element={<SearchContainer />} />
          <Route path="/pets/:type" element={<Type />} />
          <Route path="/pets/:type/:pet" element={<Pet />} />
          <Route path="*" element={<PageNotFound/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
