import React, { Component } from 'react';
import { Container, Image, Form, Button } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './logo.svg';

class NavigationBar extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.search = this.search.bind(this);
    }

    search(e) {
        e.preventDefault();
        window.location.href="/pets/search/" + document.getElementById('search-value').value;
    }


    render() {
        return (
            <div>
                <Navbar expand="lg" className="nav-bar">
                    <Container>
                    <Navbar.Brand href="/"><Image src={logo} width="65px" height="65px" alt="logo" className="logo"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav>
                            <Nav.Link href="/pets">Pets</Nav.Link>
                            <Nav.Link href="/articles">Articles</Nav.Link>
                            <Nav.Link href="/tank-builder">Tank Builder</Nav.Link>
                            <Nav.Link href="/about">About</Nav.Link>
                            <Nav.Link href="/terms-and-conditions">Terms</Nav.Link>
                            <Form inline className="nav-search" onSubmit={e => this.search(e)}>
                                <Form.Control type="text" placeholder="e.g. Black Lab" className="mr-sm-2" id="search-value"/>
                                <Button variant="outline-primary" className="search-button-nav" type="submit">Search</Button>
                            </Form>
                        </Nav>
                    </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        )
    }
}

export default NavigationBar;