import React, { Component } from 'react';
import './css/TankBuilder.css';
import NavigationBar from '../../NavigationBar';
import Footer from '../../Footer/Footer';
import { Helmet } from 'react-helmet';
import { Container, Image, Spinner, Breadcrumb, Col, ListGroup, Row, Tab, Button } from 'react-bootstrap';
import { MdInfo } from "react-icons/md";
import {API} from '../../variables/variables';

class TankBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_substrate: "",
            substrates: [
                {display_text: 'Sand', color: 'sandybrown'},
                {display_text: 'Clay', color: '#B66a50'},
                {display_text: 'Soil', color: 'brown'},
                {display_text: 'Gravel', color: 'gray'}
            ],
            tank_sizes: [
                {display_text: '10 gallons', dimensions: {length : 12, height: 8}, gallons: 10},
                {display_text: '15 gallons', dimensions: {length: 24, height: 12}, gallons: 15},
                {display_text: '25 gallons' , dimensions: {length: 24, height: 20}, gallons: 25},
                {display_text: '30 gallons' , dimensions: {length: 36, height: 12}, gallons: 30},
                {display_text: '40 gallons' , dimensions: {length: 36, height: 16}, gallons: 40},
                {display_text: '50 gallons' , dimensions: {length: 36, height: 19}, gallons: 50},
                {display_text: '55 gallons' , dimensions: {length: 48, height: 21}, gallons: 55},
                {display_text: '65 gallons', dimensions: {length: 36, height: 24}, gallons: 65},
                {display_text: '75 gallons', dimensions: {length: 48, height: 21}, gallons: 75},
                {display_text: '90 gallons', dimensions: {length: 48, height: 24}, gallons: 90},
                {display_text: '125 gallons', dimensions: {length: 72, height: 21}, gallons: 125},
                {display_text: '150 gallons', dimensions: {length: 72, height: 28}, gallons: 150}
            ],
            selected_tank_size: '',
            selected_tank_gallons: 0,
            fish: [
            ],
            pixels_per_inch_conversion: 15,
            finding_issues: false
        }
        this.change_tank_size = this.change_tank_size.bind(this);
        this.set_substrate = this.set_substrate.bind(this);
        this.move_fish = this.move_fish.bind(this);
        this.clear_fish = this.clear_fish.bind(this);
        this.remove_fish = this.remove_fish.bind(this);
        this.add_fish = this.add_fish.bind(this);
        this.get_current_fish_length = this.get_current_fish_length.bind(this);
        this.find_issues = this.find_issues.bind(this);
    }

    async componentDidMount() {
        const initialTank = this.state.tank_sizes[0];
        const initialSubstrate = this.state.substrates[0];

        const types_reponse = await fetch(API + '/tank-builder/fish-types', {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
        if(types_reponse.ok){
            let data = await types_reponse.json();
            data = data.data;
            let fish = []
            for(let i = 0; i < data.length; i++){
                fish.push({id: data[i].id, display_text: data[i].name, dimensions: {length: data[i].length, height: data[i].height}, image_path: data[i].image_path, direction: 'left', count: 0, min_tank_size: data[i].min_tank_size, issues: [], is_bottom_walking: data[i].is_bottom_walking});
            }
            this.setState({fish:fish}, () => {
                this.setState({
                    selected_tank_size: initialTank.display_text,
                    selected_tank_gallons: initialTank.gallons,
                    selected_substrate: initialSubstrate.display_text
                }, () => {
                    this.perform_action('resize_tank', initialTank);  // Set initial tank size and validate fish
                    this.perform_action('set_substrate', initialSubstrate);
                });

                setInterval(() => {
                    this.move_fish();
                }, 50);
            });
        }
        else{

        }
    }

    perform_action(action, item) {
        switch(action) {
            case 'resize_tank':
                console.log('resize tank');
                const tank = this.change_tank_size(item); // Modify tank size on the page
                const updatedFish = this.clear_fish(); // Clear fish in the tank

                // Set multiple state properties in a single `setState` call
                this.setState({
                    fish: updatedFish,
                    selected_tank_size: tank.display_text,
                    selected_tank_gallons: tank.gallons
                }, () => {
                    this.find_issues(); // Run find_issues after state update
                });
                break;
            case 'add_fish':
                console.log('add fish');
                let new_fish_added = this.add_fish(item);
                this.setState({fish: new_fish_added}, () => {
                    this.find_issues();
                });
                break;
            case 'remove_fish':
                console.log('remove fish');
                let new_fish_removed = this.remove_fish(item);
                this.setState({fish:new_fish_removed}, () => {
                    this.find_issues();
                });
                break;
            case 'set_substrate':
                console.log('set substrate');
                let substrate_item = this.set_substrate(item);
                this.setState({selected_substrate: substrate_item.display_text}, () => {
                    this.find_issues();
                });
                break;
        }
    }

    set_substrate(substrate_item) {
        let substrate = document.getElementById('substrate');
        substrate.style.backgroundColor = substrate_item.color;
        return substrate_item;
    }

    change_tank_size(tank) {
        let aquarium = document.getElementById('aqarium');
        let substrate = document.getElementById('substrate');
        let width = tank.dimensions.length * this.state.pixels_per_inch_conversion;
        let aqarium_height = (tank.dimensions.height * this.state.pixels_per_inch_conversion);
        let height = (tank.dimensions.height * this.state.pixels_per_inch_conversion) * 0.9;
        let substrate_height = aqarium_height* 0.1;


        aquarium.style.width = width.toString() + "px";
        aquarium.style.height = height.toString() + "px";

        substrate.style.width = width.toString() + "px";
        substrate.style.height = substrate_height.toString() + "px";
        return tank;
    }

    add_fish(fish) {
        let aquarium = document.getElementById('aqarium');
        let fish_image = document.createElement('img');
        fish_image.src = fish.image_path;
        let length = fish.dimensions.length * this.state.pixels_per_inch_conversion;
        fish_image.style.width = length.toString() + 'px';
        // fish_image.style.height = height.toString() + 'px';
        let new_fish = this.state.fish;

        new_fish.map((new_fish_obj) => {
            if(new_fish_obj.display_text === fish.display_text){
                new_fish_obj.count = new_fish_obj.count + 1;
            }
        });

        const maxX = aquarium.clientWidth - length;
        const maxY = aquarium.clientHeight - length;
        const randomX = Math.floor(Math.random() * maxX);
        const randomY = Math.floor(Math.random() * maxY);
        if(fish.is_bottom_walking){
            fish_image.style.left = randomX.toString() + 'px';
            fish_image.style.top = maxY + 'px';
        }
        else{
            fish_image.style.left = randomX.toString() + 'px';
            fish_image.style.top = randomY.toString() + 'px';
        }
        fish_image.dataset.direction = fish.direction;
        fish_image.dataset.speed = fish.speed;
        fish_image.dataset.fish_name = fish.display_text;

        fish_image.style.position = 'absolute';
        fish_image.className = 'fish';
        aquarium.appendChild(fish_image);

        return new_fish;
    }

    move_fish() {
        let fishElements = document.getElementsByClassName('fish');
        let fishArray = Array.from(fishElements); // Convert HTMLCollection to an array
        let aquarium = document.getElementById('aqarium');

        fishArray.map((fish) => {
            let direction = fish.dataset.direction;
            // Get the current left position, remove 'px', and parse as integer
            let currentLeft = parseInt(fish.style.left, 10) || 0;
            let length = fish.clientWidth;
            let maxX = aquarium.clientWidth - length;

            if(direction === 'left'){
                // decrease the position by 1
                if((currentLeft - 2) <= 0){
                    // invert image and change the direction.
                    if(fish.direction === 'left'){
                        fish.style.transform = 'scaleX(1)';
                    }else{
                        fish.style.transform = 'scaleX(-1)';
                    }

                    fish.dataset.direction = 'right';
                }else{
                    fish.style.left = (currentLeft - 2) + 'px';
                }
            }else{
                if((currentLeft + 2) >= maxX){
                    // invert the image and change direction.
                    if(fish.direction === 'left'){
                        fish.style.transform = 'scaleX(-1)';
                    }else{
                        fish.style.transform = 'scaleX(1)';
                    }
                    fish.dataset.direction = 'left';
                }else{
                    // increase the position by 1
                    fish.style.left = (currentLeft + 2) + 'px';
                }
            }
        });
    }

    clear_fish(){
        let fishElements = document.getElementsByClassName('fish');
        let fishArray = Array.from(fishElements); // Convert HTMLCollection to an array

        fishArray.map((fish) => {
            fish.remove();
        });
        let new_fish = this.state.fish;
        new_fish.map((fish) => {
            fish.count = 0;
        });
        return new_fish;
    }

    remove_fish(fish) {
        let fishElements = document.getElementsByClassName('fish');
        let fishArray = Array.from(fishElements); // Convert HTMLCollection to an array
        let new_fish = this.state.fish;
        let found = false;
        fishArray.map((fish_element) => {
            if(fish.display_text === fish_element.dataset.fish_name && found === false){
                fish_element.remove();
                new_fish.map((fish_obj) => {
                    if(fish_obj.display_text === fish.display_text){
                        fish_obj.count = fish_obj.count -1;
                    }
                });
                found = true;
            }
        });
        return new_fish;
    }

    async find_issues() {
        this.setState({finding_issues : true}, async () => {
            let new_fish = this.state.fish;
            let combined_fish_length = this.get_current_fish_length();

            let distinct_ids = new_fish.filter(item => item.count > 0).map(item => item.id)
            const types_reponse = await fetch(API + '/tank-builder/incompatible-types', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({'current_fish_ids' : distinct_ids})
            });
            if(types_reponse.ok){
                let data = await types_reponse.json();
                data = data.data;
                new_fish.map((fish) => {
                    fish.issues = []
                    if((fish.dimensions.length + combined_fish_length) > this.state.selected_tank_gallons){
                        fish.issues.push('Can\'t add any more of these for the size of the tank.');
                    }
                    if(fish.min_tank_size > this.state.selected_tank_gallons){
                        fish.issues.push('Minimum tank required is ' + fish.min_tank_size.toString() + ' gallons');
                    }
                });
                data.map((item) => {
                    let current_fish = new_fish.filter(fish => fish.id === item.id);
                    current_fish = current_fish[0];
                    item.incompatible_with.map((id) => {
                        new_fish.map((fish) => {
                            if(fish.id === id.id){
                                fish.issues.push("Incompatible with " + current_fish.display_text);
                            }
                        })
                    });
                });

                this.setState({fish : new_fish}, () => {
                    this.setState({finding_issues : false});
                });
            }
            else{
                // GOTTA DO SOMETHING.
            }
        });
    }

    get_current_fish_length() {
        let fishElements = document.getElementsByClassName('fish');
        let fishArray = Array.from(fishElements); // Convert HTMLCollection to an array

        let length = 0;
        fishArray.map((fish_element) => {
            let fish_name = fish_element.dataset.fish_name;
            let matchedFish = this.state.fish.find(fish => fish.display_text === fish_name);
            length += matchedFish.dimensions.length;
        });

        return length;
    }

    render() {
        return (
            <div>
                <div className="page-body">
                    <Helmet>
                        <title>Pet Quick Start Tank Builder</title>
                        <meta name="description" content="Discover the ultimate guide to building a stunning freshwater fish tank. From choosing the perfect tank and equipment to selecting vibrant aquatic life, create a serene underwater world in your home with our expert tips and step-by-step instructions."/>
                        <link rel="canonical" href="https://petquickstart.com/articles" />
                    </Helmet>
                    <NavigationBar />
                    <Container>
                        <header className="hero" role="banner">
                                <Breadcrumb className="breadcrumb-bar">
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Freshwater Fish Tank Builder
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            <div className="hero-content">
                                <h1 className="title-text">Freshwater Fish Tank Builder</h1>
                                <p>Use the freshwater fish tank builder to plan your next aqurium. We are still building this at the moment, but are excited to show off what it is currently capable of doing.</p>
                                <p><b>Plans for the future</b></p>
                                <ul>
                                    <li>Add specific fish species.</li>
                                    <li>Change the substrate options to be images as to improve realism.</li>
                                    <li>Add in filter options.</li>
                                    <li>Add in resizable plants / hardscape.</li>
                                    <li>As you add to your tank, below it will add the real life items for you to purchase.</li>
                                </ul>
                            </div>
                        </header>
                        <div className="clear-div"></div>
                        <Tab.Container defaultActiveKey="#link1" className="aquarium-options-container">
                            <Col>
                                <Row className="justify-content-center">
                                    <ListGroup horizontal>
                                        <ListGroup.Item action href="#link1" className="aquarium-options-link">
                                        Tank Sizes
                                        </ListGroup.Item>
                                        <ListGroup.Item action href="#link2">
                                        Substrates
                                        </ListGroup.Item>
                                        <ListGroup.Item action href="#link3">
                                        Fish
                                        </ListGroup.Item>
                                        <ListGroup.Item action href="#link4">
                                        Plants
                                        </ListGroup.Item>
                                        <ListGroup.Item action href="#link5">
                                        Hardscape
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Row>

                                <Tab.Content>
                                    <br></br>
                                    <Tab.Pane eventKey="#link1">
                                        <div className="grid-container">
                                            {this.state.tank_sizes.map((tank, index) => (
                                                <div className={this.state.selected_tank_size === tank.display_text ? "selected-grid-item grid-item" : "grid-item"} key={index}
                                                    onClick={() => this.perform_action('resize_tank', tank)}>
                                                    {tank.display_text}
                                                </div>
                                            ))}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#link2">
                                        <div className="grid-container">
                                            {this.state.substrates.map((substrate, index) => (
                                                <div className={this.state.selected_substrate === substrate.display_text ? "selected-grid-item grid-item" : "grid-item"}
                                                    key={index} style={{backgroundColor : substrate.color}} onClick={() => this.perform_action('set_substrate', substrate)}>
                                                    {substrate.display_text}
                                                </div>
                                            ))}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#link3">
                                        <div className="grid-container">
                                            {this.state.fish.map((fish, index) => (
                                                <div>
                                                    {fish.issues.length === 0 ?
                                                        <div className="fish-grid-item"
                                                            key={index}>
                                                            <Image src={fish.image_path} style={{width:'40px'}} alt={fish.display_text}></Image>
                                                            <br></br>
                                                            {fish.display_text}
                                                            <div className='fish-counter-container'>
                                                                <Button size='sm' variant='dark' onClick={() => this.perform_action('remove_fish', fish)}>-</Button>
                                                                {' '}
                                                                {fish.count}
                                                                {' '}
                                                                <Button size='sm' variant='dark' onClick={() => this.perform_action('add_fish', fish)} disabled={this.state.finding_issues?'true':''}>+</Button>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="fish-grid-item"
                                                            key={index}>
                                                            <Image src={fish.image_path} style={{width:'40px'}} alt={fish.display_text}></Image>
                                                            <br></br>
                                                            {fish.display_text}
                                                            <div className='fish-counter-container'>
                                                                {fish.count > 0 ? <Button size='sm' variant='dark' onClick={() => this.perform_action('remove_fish', fish)}>-</Button> : <Button size='sm' variant='light' disabled='true'>-</Button>}
                                                                {' '}
                                                                {fish.count}
                                                                {' '}
                                                                <Button size='sm' variant='light' disabled='true'>+</Button>
                                                            </div>
                                                            <div>
                                                                <div className='hover-click tank-tooltip'>
                                                                    <MdInfo className='danger' />
                                                                    <span className="tooltiptext">
                                                                        <ol className='issues-list'>
                                                                            {fish.issues.map((issue, index) => (
                                                                                <li className='danger' key={index}>{issue}</li>
                                                                            ))}
                                                                        </ol>
                                                                    </span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#link4">
                                        Plants
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="#link5">
                                        Hardscape
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Tab.Container>
                        <br></br>
                        <hr></hr>
                        <div className="aquarium" id='aqarium'>

                        </div>
                        <div className="substrate" id='substrate'>

                        </div>

                    </Container>
                </div>
                <br></br>
                <Footer />
            </div>
        )
    }
}

export default TankBuilder;