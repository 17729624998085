import React, { useState } from 'react';
import './Tooltip.css'; // Make sure to create this CSS file

const Tooltip = ({ children, content }) => {
    const [visible, setVisible] = useState(false);

    const showTooltip = () => setVisible(true);
    const hideTooltip = () => setVisible(false);

    return (
        <div 
            className="tooltip-container"
            onMouseEnter={showTooltip}
            onMouseLeave={hideTooltip}
            onClick={showTooltip}
        >
            {children}
            {visible && <div className="tooltip-content">{content}</div>}
        </div>
    );
};

export default Tooltip;