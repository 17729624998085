import React, { Component} from 'react';
import { Container, Image, Spinner, Breadcrumb } from 'react-bootstrap';
import NavigationBar from '../../NavigationBar';
import { Helmet } from 'react-helmet';
import { API } from '../../variables/variables';
import Footer from '../../Footer/Footer';

class Articles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            loading: true
        }
    }

    async componentDidMount() {
        const articles_response = await fetch(API + '/articles', {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
        if(articles_response.ok){
            const data = await articles_response.json();
            this.setState({articles : data.data});
        }
        else{

        }
        this.setState({loading : false});
    }

    render() {
        return (
            <div>
                <div className="page-body">
                <Helmet>
                    <title>Pet Quick Start Articles</title>
                    <meta name="description" content="Discover expertly written pet care articles covering grooming, training, health, and more at PetQuickStart.com. Your go-to resource for all things pet!"/>
                    <link rel="canonical" href="https://petquickstart.com/articles" />
                </Helmet>
                <NavigationBar/>
                <Container>
                    <header className="hero" role="banner">
                            <Breadcrumb className="breadcrumb-bar">
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Articles
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        <div className="hero-content">
                            <h1 className="title-text">Articles</h1>
                        </div>
                    </header>
                    <div className="clear-div"></div>
                    {this.state.loading? 
                        <div className="center">
                            <Spinner className="loading-spinner"/>
                        </div>
                        :
                        <div className="center">
                            {this.state.articles.map((article, index) =>
                                <div key={index}>
                                    <a href={"/articles/" + article.article_name} className="article-nav-link"><h2>{article.article_name}</h2>
                                        <ul className="link-list">
                                            <li><b>Views:</b> {article.views}</li>
                                        </ul>
                                    </a>
                                </div>
                            )}
                        </div>
                    }
                </Container>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Articles;