import React, { Component} from 'react';
import { Container, Image } from 'react-bootstrap';
import logo from '../logo.svg';
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { ImFacebook2 } from "react-icons/im"
import { FaCircle } from "react-icons/fa";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div className="footer">
                <Container>
                    <div className="footer-container">
                        <div>
                            <Image src={logo} width="85px" height="85px" alt="logo" className="logo"/>
                            <h5><b>Disclaimer</b></h5>
                                <div className="disclaimer">
                                    <p>We cannot guarantee 100% accuracy and take no responsibility for our data completion.</p>
                            </div>
                        </div>
                        <div className="footer-pages-container">
                            <div className="footer-inner">
                                <div>
                                    <h5>Pages</h5>
                                    <div className="">
                                        <a href="/pets">Pets</a>
                                        <br></br>
                                        <a href="/about">About</a>
                                        <br></br>
                                        <a href="/terms-and-conditions">Terms</a>
                                        <br></br>
                                        <a href="/articles">Articles</a>
                                        <br></br>
                                        <a href="/shop">Shop</a>
                                        <br></br>
                                        <a href="/">Home</a>
                                    </div>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                        <div className="footer-social-container">
                            <h5>Follow Us</h5>
                            <div className="social-container">
                                <a href="https://www.instagram.com/petquickstart/"><FaInstagram className="social"/></a>
                                <br></br>
                                <br></br>
                                <a href="https://x.com/petquickstart"><FaXTwitter className="social"/></a>
                                <br></br>
                                <br></br>
                                <a href="https://www.facebook.com/profile.php?id=61559868362533"><ImFacebook2 className="social"/></a>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="footer-bottom">
                        <p className="float-left">Pet Quick Start</p>
                        <div className="float-right">
                            <FaCircle className="bottom-circle"/>
                            <FaCircle className="bottom-circle"/>
                            <FaCircle className="bottom-circle"/>
                            <FaCircle className="bottom-circle"/>
                            <FaCircle className="bottom-circle"/>
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}

export default Footer;