import React, { Component} from 'react';
import { Container, Spinner, Image, Button, Card, Breadcrumb } from 'react-bootstrap';
import NavigationBar from '../../NavigationBar';
import {API} from '../../variables/variables';
import { Helmet } from 'react-helmet';
import { RiTimeLine } from "react-icons/ri";
import { IoScaleSharp } from "react-icons/io5";
import { GiBodyHeight } from "react-icons/gi";
import { FaHeartbeat } from "react-icons/fa";
import { FaRunning } from "react-icons/fa";
import { FaHandHoldingHeart } from "react-icons/fa6";
import { MdChildFriendly } from "react-icons/md";
import { GiCommercialAirplane } from "react-icons/gi";
import { GiComb } from "react-icons/gi";
import { FaBoxTissue } from "react-icons/fa";
import { FaPeace } from "react-icons/fa";
import { FaHandsHoldingCircle } from "react-icons/fa6";
import { MdFamilyRestroom } from "react-icons/md";
import { FaRegFaceLaugh } from "react-icons/fa6";
import Tooltip from './Tooltip';
import Footer from '../../Footer/Footer';

class PetQuickStartGuide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pet: this.props.pet,
            type: this.props.type,
            data: {},
            selected_requirements: [],
            selected_requirement: "",
            loading: true
        }
        this.formatNumber = this.formatNumber.bind(this);
    }

    async componentDidMount() {
        const types_reponse = await fetch(API + '/pet?name=' + this.props.pet, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
        if(types_reponse.ok){
            const data = await types_reponse.json();
            this.setState({data : data.data}, () => {
                this.setState({selected_requirements : this.state.data.requirements.habitat});
                this.setState({selected_requirement : "habitat"});
            });
        }
        else{

        }
        // await fetch(API + '/views?type=pet&name=' + this.props.pet, {
        //     method: 'POST',
        //     headers: {'Content-Type': 'application/json'}
        // });

        this.setState({loading : false});
    }

    formatNumber = (value) => {
        const num = Number(value);
        return num % 1 === 0 ? num.toString() : num.toFixed(2);
    };

    render() {
        return (
            <div>
                <div className="page-body">
                <Helmet>
                    <title>{this.state.pet} Care - Quick Start Guide</title>
                    <meta name="description" content={this.state.data.metadata}></meta>
                    <link rel="canonical" href={"https://petquickstart.com/pets/" + this.state.type.replace(/ /g, "%20") + "/" + this.state.pet.replace(/ /g, "%20")}  />
                </Helmet>
                <NavigationBar/>
                <Container>
                    <section>
                        <header className="hero" role="banner">
                            <Breadcrumb className="breadcrumb-bar">
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item href='/pets'>
                                    Pet Types Gallery
                                </Breadcrumb.Item>
                                <Breadcrumb.Item href={'/pets/' + this.state.type}>
                                    {this.state.type} Gallery
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    {this.state.pet} Quick Start Guide
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <div className="clear-div">
                            </div>
                            <Button variant="dark" href={'/pets/' + this.state.type}>Back</Button>
                            <br></br>
                            <br></br>
                            <div className="hero-content-guide difficulty-section">
                                {this.state.loading?
                                    <div></div>
                                    :
                                    <div className="circle-background">
                                        <div className="center">
                                            <Image src={this.state.data.picture_url} rounded alt={this.state.data.name} className="guide-img"/>
                                        </div>
                                    </div>
                                }
                                <br></br>
                                <br></br>
                                <h1 className="center">{this.state.pet}</h1>
                                <h3 className="center">Quick Start Care Guide</h3>
                                {this.state.loading?
                                    <>
                                    </>:
                                    <p><b>Views: </b>{this.state.data.views}</p>
                                }
                                <p><b>3 - 5 minute read <RiTimeLine /></b></p>
                                <p><b>Updated on</b>: {this.state.data.updatedAt}</p>
                                <hr></hr>
                            </div>
                            <div className="clear-div"></div>
                        </header>
                    </section>
                    <div className="clear-div">
                    </div>
                    <section>
                        <br></br>
                        {this.state.loading?
                            <div className="center">
                                <Spinner className="loading-spinner"/>
                            </div>
                            :
                            <div className="entire-document">
                                {/* Table Of Contents */}
                                <Card>
                                    <Card.Header className="section-heading"><h2 className="">Table Of Contents</h2>
                                    </Card.Header>
                                </Card>
                                <br></br>
                                <div className="inner-body">
                                    <ul className="req-list table-of-contents">
                                        <li><a href="#quick-profile-section">Quick Profile</a></li>
                                        <li><a href="#difficulty-section">Difficulty & Reasons</a></li>
                                        <li><a href="#guide-section">Guide</a></li>
                                        <ul className="inner-contents-list">
                                            <li><a href="#habitat">Habitat</a></li>
                                            <li><a href="#diet">Diet</a></li>
                                            <li><a href="#hygiene">Hygiene</a></li>
                                            <li><a href="#exerciset">Exercise</a></li>
                                            <li><a href="#vet">Health / Vet</a></li>
                                            <li><a href="#training">Training</a></li>
                                        </ul>
                                    </ul>
                                </div>
                                {/* The quick profile section */}
                                <div className="quick-profile" id="quick-profile-section">
                                    <Card>
                                        <Card.Header className="section-heading"><h2>Quick Profile</h2></Card.Header>
                                    </Card>
                                    <br></br>
                                    <div className="inner-body">
                                        <div className="quick-profile-grid">
                                            <Tooltip content="The general height range for this animal.">
                                            <div className="quick-profile-body">
                                                <div className="quick-profile-item">
                                                    <div className="quick-profile-icon">
                                                        <GiBodyHeight />
                                                    </div>
                                                    <h3>Height</h3>
                                                    <p className="center">{this.formatNumber(this.state.data.quick_profile.height_range[0])} - {this.formatNumber(this.state.data.quick_profile.height_range[1])} inches</p>
                                                </div>
                                            </div>
                                            </Tooltip>
                                            <Tooltip content="The general weight range for the animal.">
                                            <div className="quick-profile-body">
                                                <div className="quick-profile-item">
                                                    <div className="quick-profile-icon">
                                                        <IoScaleSharp />
                                                    </div>
                                                    <h3>Weight</h3>
                                                    <p className="center">{this.formatNumber(this.state.data.quick_profile.weight_range[0])} - {this.formatNumber(this.state.data.quick_profile.weight_range[1])} pounds</p>
                                                </div>
                                            </div>
                                            </Tooltip>
                                            <Tooltip content="The general lifespan of the animal.">
                                            <div className="quick-profile-body">
                                            <div className="quick-profile-item">
                                                <div className="quick-profile-icon">
                                                    <FaHeartbeat />
                                                </div>
                                                <h3>Lifespan</h3>
                                                <p className="center">{this.formatNumber(this.state.data.quick_profile.lifespan_range[0])} - {this.formatNumber(this.state.data.quick_profile.lifespan_range[1])} years</p>
                                            </div>
                                        </div>
                                        </Tooltip>
                                        <Tooltip content="The best suited living environment for the animal.">
                                        <div className="quick-profile-body">
                                            <div className="quick-profile-item">
                                                <div className="quick-profile-icon">
                                                    <MdFamilyRestroom/>
                                                </div>
                                                <h3>Environment</h3>
                                                <p className="center">{this.state.data.quick_profile.living_environment.map((place, index) =>
                                                     <span key={index}>
                                                        {index === this.state.data.quick_profile.living_environment.length -1?
                                                            <span>{place}</span>
                                                            :
                                                            <span>{place}, </span>
                                                        }
                                                    </span>
                                                )}</p>
                                            </div>
                                        </div>
                                        </Tooltip>
                                        <Tooltip content="The personality adjectives that generally describe the animal.">
                                        <div className="quick-profile-body">
                                            <div className="quick-profile-item">
                                                <div className="quick-profile-icon">
                                                    <FaRegFaceLaugh />
                                                </div>
                                                <h3>Personality</h3>
                                                <p className="center">{this.state.data.quick_profile.personality.map((adjective, index) =>
                                                     <span key={index}>
                                                        {index === this.state.data.quick_profile.personality.length -1?
                                                            <span>{adjective}</span>
                                                            :
                                                            <span>{adjective}, </span>
                                                        }
                                                    </span>
                                                )}</p>
                                            </div>
                                        </div>
                                        </Tooltip>
                                        <Tooltip content="The general activity level for this animal.">
                                        <div className="quick-profile-body">
                                            <div className="quick-profile-item">
                                                <div className="quick-profile-icon">
                                                    <FaRunning />
                                                </div>
                                                <h3>Activity Level</h3>
                                                <p className="center">{this.state.data.quick_profile.activity_level}</p>
                                            </div>
                                        </div>
                                        </Tooltip>
                                        <Tooltip content="The general affection level of the animal.">
                                        <div className="quick-profile-body">
                                            <div className="quick-profile-item">
                                                <div className="quick-profile-icon">
                                                    <FaHandHoldingHeart />
                                                </div>
                                                <h3>Affection Level</h3>
                                                <p className="center">{this.state.data.quick_profile.affection_level}</p>
                                            </div>
                                        </div>
                                        </Tooltip>
                                        <Tooltip content="If the animal can generally be held at all by the owner.">
                                        <div className="quick-profile-body">
                                            <div className="quick-profile-item">
                                                <div className="quick-profile-icon">
                                                    <FaHandsHoldingCircle />
                                                </div>
                                                <h3>Handleable</h3>
                                                <p className="center">{this.state.data.quick_profile.handleable === true?
                                                    <span>Yes</span>
                                                    :
                                                    <span>No</span>}</p>
                                            </div>
                                        </div>
                                        </Tooltip>
                                        <Tooltip content="If the animal can generally live with it's own kind.">
                                        <div className="quick-profile-body">
                                            <div className="quick-profile-item">
                                                <div className="quick-profile-icon">
                                                    <FaPeace/>
                                                </div>
                                                <h3>Coexist</h3>
                                                <p className="center">{this.state.data.quick_profile.coexist === true?
                                                    <span>Yes</span>
                                                    :
                                                    <span>No</span>}</p>
                                            </div>
                                        </div>
                                        </Tooltip>
                                        <Tooltip content="If the animal is hypoallergenic meaning it doesn't cause allergies.">
                                        <div className="quick-profile-body">
                                            <div className="quick-profile-item">
                                                <div className="quick-profile-icon">
                                                    <FaBoxTissue/>
                                                </div>
                                                <h3>Hypoallergenic</h3>
                                                <p className="center">{this.state.data.quick_profile.hypoallergenic === true?
                                                    <span>Yes</span>
                                                    :
                                                    <span>No</span>}</p>
                                            </div>
                                        </div>
                                        </Tooltip>
                                        <Tooltip content="If the animal generally sheds or not.">
                                        <div className="quick-profile-body">
                                            <div className="quick-profile-item">
                                                <div className="quick-profile-icon">
                                                    <GiComb />
                                                </div>
                                                <h3>Sheds</h3>
                                                <p className="center">{this.state.data.quick_profile.sheds === true?
                                                    <span>Yes</span>
                                                    :
                                                    <span>No</span>}</p>
                                            </div>
                                        </div>
                                        </Tooltip>
                                        <Tooltip content="If the animal is generally travel friendly.">
                                        <div className="quick-profile-body">
                                            <div className="quick-profile-item">
                                                <div className="quick-profile-icon">
                                                    <GiCommercialAirplane/>
                                                </div>
                                                <h3>Travel Friendly</h3>
                                                <p className="center">{this.state.data.quick_profile.travel_friendly === true?
                                                    <span>Yes</span>
                                                    :
                                                    <span>No</span>}</p>
                                            </div>
                                        </div>
                                        </Tooltip>
                                        <Tooltip content="If the animal is generally ok to be around children.">
                                            <div className="quick-profile-body">
                                                <div className="quick-profile-item">
                                                    <div className="quick-profile-icon">
                                                        <MdChildFriendly />
                                                    </div>
                                                    <h3>Child Friendly</h3>
                                                    <p className="center">{this.state.data.quick_profile.child_friendly === true?
                                                        <span>Yes</span>
                                                        :
                                                        <span>No</span>}</p>
                                                </div>
                                            </div>
                                        </Tooltip>
                                        </div>

                                    </div>
                                </div>
                                <br></br>
                                {/* The difficulty section */}
                                <div className="difficulty-section" id="difficulty-section">
                                    <Card>
                                        <Card.Header className="section-heading"><h2 className="">Difficulty - {this.state.data.difficulty_rating}</h2>
                                        </Card.Header>
                                    </Card>
                                    <br></br>
                                    <div className="inner-body">
                                        <h3 className="requirement-header">Cost</h3>
                                        <ul>
                                            <li>{this.state.data.difficulty_factors.dif_cost}</li>
                                        </ul>
                                        <h3 className="requirement-header">Time</h3>
                                        <ul>
                                            <li>{this.state.data.difficulty_factors.dif_time}</li>
                                        </ul>
                                        <h3 className="requirement-header">Knowledge</h3>
                                        <ul>
                                            <li>{this.state.data.difficulty_factors.dif_knowledge}</li>
                                        </ul>
                                        <h3 className="requirement-header">Maintenance</h3>
                                        <ul>
                                            <li>{this.state.data.difficulty_factors.dif_maintenance}</li>
                                        </ul>
                                    </div>
                                </div>
                                <br></br>
                                {/* The guide section */}
                                <div className="guide" id="guide-section">
                                    <Card >
                                        <Card.Header className="section-heading"><h2>Guide</h2></Card.Header>
                                    </Card>
                                    <div className='inner-body'>
                                        <br></br>
                                        <h3 className="requirement-header" id="habitat">Habitat</h3>
                                        <ul className="req-list">
                                            {this.state.data.requirements.habitat.map((req, index) =>
                                                <li key={index}>{req}</li>
                                            )}
                                        </ul>
                                        <h3 className="requirement-header" id="diet">Diet</h3>
                                        <ul className="req-list">
                                            {this.state.data.requirements.diet.map((req, index) =>
                                                <li key={index}>{req}</li>
                                            )}
                                        </ul>
                                        <h3 className="requirement-header" id="hygiene">Hygiene</h3>
                                        <ul className="req-list">
                                            {this.state.data.requirements.hygiene.map((req, index) =>
                                                <li key={index}>{req}</li>
                                            )}
                                        </ul>
                                        <h3 className="requirement-header" id="exercise">Exercise</h3>
                                        <ul className="req-list">
                                            {this.state.data.requirements.exercise.map((req, index) =>
                                                <li key={index}>{req}</li>
                                            )}
                                        </ul>
                                        <h3 className="requirement-header" id="vet">Health / Vet</h3>
                                        <ul className="req-list">
                                            <li><b>Care Tips</b></li>
                                            <ul>
                                                {this.state.data.requirements.vet.requirements.map((req, index) =>
                                                    <li key={index}>{req}</li>
                                                )}
                                            </ul>
                                            <li><b>Illnesses To Look Out For</b></li>
                                            <ul>
                                            {this.state.data.requirements.vet.illnesses.map((illness, index) =>
                                                <div>
                                                     <li key={index}><a href={illness.link}>{illness.name}</a></li>
                                                     <ul>
                                                        <li>{illness.definition}</li>
                                                     </ul>
                                                </div>
                                            )}
                                            </ul>
                                        </ul>
                                        <h3 className="requirement-header" id="training">Training</h3>
                                        <ul className="req-list">
                                            {this.state.data.requirements.training.map((req, index) =>
                                                <li key={index}>{req}</li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                    </section>
                    <br></br>
                </Container>
                </div>
                <Footer />
            </div>
        )
    }
}

export default PetQuickStartGuide;