import React, { Component} from 'react';
import { Container, Form, Spinner, Image, Button, Breadcrumb } from 'react-bootstrap';
import NavigationBar from '../../NavigationBar';
import {API} from '../../variables/variables';
import { Helmet } from 'react-helmet';
import Footer from '../../Footer/Footer';

class PetTypesGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            petType : "",
            gallery: [],
            searchGallery: [],
            selected: [],
            loading: true
        }
        this.search = this.search.bind(this);
        this.alterSelected = this.alterSelected.bind(this);
    }

    async componentDidMount() {
        const types_reponse = await fetch(API + '/types', {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
        if(types_reponse.ok){
            const data = await types_reponse.json();
            this.setState({gallery : data.data});
            this.setState({searchGallery : data.data});
        }
        else{

        }
        this.setState({loading : false});
    }

    search() {
        let searchGallery = this.state.gallery;
        let selected = this.state.selected;
        if(selected.length !== 0){
            searchGallery = searchGallery.filter(petType => selected.includes(petType.plural));
        }
        this.setState({searchGallery : searchGallery});
    }

    alterSelected(value) {
        let selected = this.state.selected;
        if(selected.includes(value)){
            selected = selected.filter(item => item !== value);
        }
        else{
            selected.push(value);
        }
        this.setState({selected : selected}, () => {
            this.search();
        });
    }

    render() {
        return (
            <div>
                <div className="page-body">
                <Helmet>
                    <title>Pet Types Gallery - Pet Quick Start</title>
                    <meta name="description" content="Explore any kind of pet you can own!"/>
                    <link rel="canonical" href={"https://petquickstart.com/pets"} />
                </Helmet>
                <NavigationBar/>
                <Container>
                    <section>
                        <header className="hero" role="banner">
                            <Breadcrumb className="breadcrumb-bar">
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Pet Types Gallery
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <div className="hero-content">
                                <h1 className="title-text">Find Your Next Best Friend</h1>
                            </div>
                            <div className="clear-div">
                            </div>
                        </header>
                    </section>
                    <div className="clear-div">
                    </div>
                    <section>
                        <div className="pills">
                            {this.state.loading?
                                <div className="center">
                                </div>
                                :
                                <div className="pills">
                                    {this.state.gallery.map((type, index) =>
                                        <div key={index}>
                                            <Button className="pills-selected" href={"/pets/" + type.type}>{type.published_count} {type.plural}</Button>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    </section>
                    <section className="desktop">
                        <br></br>
                        {this.state.loading?
                            <div className="center">
                                <Spinner className="loading-spinner"/>
                            </div>
                            :
                            <div className="gallery">
                                {this.state.searchGallery.map((type, index) =>
                                    <div key={index}>
                                        <section className="type-container">
                                        {index%2 === 0?
                                            <div className="type-out-container">
                                                <div className="description">
                                                    <h2>{type.plural}</h2>
                                                    <p>{type.description}</p>

                                                    <Button className="any-button" size="lg" href={"/pets/" + type.type}>Explore {type.published_count} {type.plural}</Button>

                                                </div>
                                                <div className="type-img-container">
                                                    <a href={"/pets/" + type.type}>
                                                        <Image className="type-img" rounded src={type.picture_url} alt={type.type} loading="lazy"/>
                                                    </a>
                                                </div>
                                                <div className="clear-div">
                                                </div>
                                            </div>
                                            :
                                            <div className="type-out-container">
                                                <div className="type-img-container">
                                                    <a href={"/pets/" + type.type}>
                                                        <Image className="type-img" rounded src={type.picture_url} alt={type.type} loading="lazy"/>
                                                    </a>
                                                </div>
                                                <div className="description">
                                                    <h2>{type.plural}</h2>
                                                    <p>{type.description}</p>

                                                    <Button className="any-button" size="lg" href={"/pets/" + type.type}>Explore {type.published_count} {type.plural}</Button>

                                                </div>
                                                <div className="clear-div">
                                                </div>
                                            </div>
                                        }
                                        </section>
                                    </div>
                                )}
                            </div>
                        }
                    </section>
                    <section className="mobile">
                        <br></br>
                        {this.state.loading?
                            <div className="center">
                                <Spinner className="loading-spinner"/>
                            </div>
                            :
                            <div>
                                {this.state.searchGallery.map((type, index) =>
                                    <div key={index}>
                                        <div className="center">
                                            <Image className="type-img" rounded src={type.picture_url} alt={type.type} loading="lazy"/>
                                        </div>
                                        <h2>{type.plural}</h2>
                                        <h5><b>Click Explore To View: </b>{type.published_count} {type.type} Breeds</h5>
                                        <p><b>Description: </b>{type.description}</p>
                                        <div className="center">
                                            <Button className="any-button" size="lg" href={"/pets/" + type.type}>Explore {type.published_count} {type.plural}</Button>
                                        </div>
                                        <br></br>
                                        <br></br>
                                    </div>
                                )}
                            </div>
                        }
                    </section>
                </Container>
                </div>
                <Footer />
            </div>
        )
    }
}

export default PetTypesGallery;