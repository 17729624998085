import React, { Component} from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import NavigationBar from '../../NavigationBar';
import { Helmet } from 'react-helmet';
import Footer from '../../Footer/Footer';
import CustomCarousel from './CustomCarousel';
import { Nav, Tab } from 'react-bootstrap';

class HomeScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <div className="page-body">
                    <Helmet>
                        <title>Pet QuickStart - Home</title>
                        <meta name="description" content="Find your ideal pet companion at PetQuickStart. Expert advice on dog breeds, cat care, and more. Start your journey to pet ownership today!"/>
                        <link rel="canonical" href={"https://petquickstart.com/"} />
                    </Helmet>
                    <NavigationBar/>
                    <Container>
                        <header className="" role="banner">
                            <div className="hero-content">
                                <h1 className="title-text landing-page-text">Everything You Need to Learn and Care for Your Perfect Pet</h1>
                                <br></br>
                                <div className="center">
                                    <Button className="any-button" size="lg" href="/pets">Start Exploring</Button>
                                </div>
                            </div>
                            <br></br>
                            <div className="hero-image">
                                <CustomCarousel />
                            </div>
                        </header>
                        <div className="clear-div"></div>
                        <Tab.Container defaultActiveKey="explore">
                            <Nav justify variant="tabs" defaultActiveKey="explore">
                                <Nav.Item>
                                    <Nav.Link eventKey="explore" className="nav-item-key"><h2>Explore</h2></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="learn" className="nav-item-key"><h2>Learn</h2></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="love" className="nav-item-key"><h2>Love</h2></Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="explore" className="pane-item">
                                    <h3 className='timtle-text'>Explore Our Database of Pets</h3>
                                    <p>We have hundreds of breeds of animals available to explore! You can easily scroll through every breed of what you're looking for until one catches your eye.</p>
                                    <br></br>
                                    <div className='center'>
                                        <Image src='https://storage.googleapis.com/pet-quick-start-profile-imgs/explore.png' className="other-carousel-image other-carousel-border" alt='explore our animals'></Image>
                                        <br></br>
                                        <br></br>
                                        <Image src='https://storage.googleapis.com/pet-quick-start-profile-imgs/explore2.png' className="other-carousel-image other-carousel-border" alt='explore our animals 2'></Image>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="learn" className="pane-item">
                                    <h3 className='timtle-text'>Learn How to Care For Any Pet</h3>
                                    <p>Once you've found a pet you're interested in, you can visit the page and learn all you need to start taking care of them.</p>
                                    <br></br>
                                    <div className='center'>
                                        <Image src='https://storage.googleapis.com/pet-quick-start-profile-imgs/learn.png' className="other-carousel-image other-carousel-border"></Image>
                                        <br></br>
                                        <br></br>
                                        <Image src='https://storage.googleapis.com/pet-quick-start-profile-imgs/learn2.png' className="other-carousel-image other-carousel-border"></Image>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="love" className="pane-item">
                                    <h3 className='timtle-text'>Love Your New Best Friend</h3>
                                    <p>Once you've read our article about the pet, you should be well equipped to begin taking care of your new best friend!</p>
                                    <br></br>
                                    <div className='center'>
                                        <Image src='https://storage.googleapis.com/pet-quick-start-profile-imgs/love.png' className="other-carousel-image other-carousel-border"></Image>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                        <br></br>
                    </Container>
                </div>
                <Footer />
            </div>
        )
    }
}

export default HomeScreen;