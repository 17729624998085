import React, { Component} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import {API} from '../../variables/variables';
import { Image } from 'react-bootstrap';

class CustomCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gallery: [],
        }
    }

    async componentDidMount() {
        const types_reponse = await fetch(API + '/types', {
            method: 'GET',
            headers: {'Content-Type': 'application/json'}
        });
        if(types_reponse.ok){
            const data = await types_reponse.json();
            this.setState({gallery : data.data});
            this.setState({searchGallery : data.data});
        }
        else{

        }
        this.setState({loading : false});
    }

    render() {
        return (
            <div>
                <Carousel className="custom-carousel-container">
                    {this.state.gallery.map((animal, index) => (
                        <Carousel.Item interval={2000} key={index}>
                            <Image className="carousel-img" rounded src={animal.picture_url} alt={animal.type} loading="lazy"/>
                            <Carousel.Caption>
                            {/* <h3 className="title-text">{animal.plural}</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        )
    }
}

export default CustomCarousel;